<template>
    <label class="checkbox-label" :class="[isChecked, { focusStyle : focusStyle }]" :style="styleComputed">
        <input type="checkbox" :id="choiseId" v-model="checked" :name="name" v-on:change="clickedCheckbox" @focus="focusStyle = true" @blur="focusStyle = false"/>
        <span class="checkbox-style" :class="checkboxClassComputed"></span>
        <span v-if="hasChoiceSlotComputed" class="text">
            <slot class="text" v-if="hasChoiceSlotComputed" name="choice" />
        </span>        
        <span v-else class="text">{{ choice }}</span>
    </label>
</template>

<script>
export default {
    name: "PopCheckbox",
    props: {
        choiseId: {
            required: true,
        },
        choice: {
            type: String,
            required: true,
        },
        isClicked: {
            type: Function,
            required: true,
            default: () => {},
        },
        startValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        name: {
            type: String,
            required: false,
        },
        large:{
            type: Boolean,
            required: false,
            default: false,
        },
        model: {
            type: Object,
            required: false,
            default: () => {},
        },
        paddingLeft: {
            type: Number,
            required: false,
        },
    },
    computed: {
        isChecked() {
            return this.checked ? 'checked': '';
        },
        hasChoiceSlotComputed() {
            return !!this.$slots['choice']
        },
        checkboxClassComputed() {
            let classes = "";
            if(this.large) {
                classes += "checkbox-large"
            }
            return classes;
        },
        styleComputed() {
            let style = {};
            if (this.paddingLeft) {
                style.paddingLeft = this.paddingLeft + "em";
            }
            return style;
        }
    },
    data() {
        return {
            checked: false,
            focusStyle: false,
        }
    },
    mounted() {
        this.checked = this.startValue;
    },
    methods: {
        clickedCheckbox(){
            this.isClicked(this.checked, this.model);
        },
    },
}
</script>

<style lang="scss" scoped>

// default style
label {
    position: relative;
    padding: 20px 20px 20px 30px;
    width: 100%;
    transition: all ease-in-out 100ms;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
    visibility: visible !important;
    opacity: 0 !important;
    width: 0;
}
.text {
    padding-left: 20px;
}

.checkbox-style {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 2px;
    box-sizing: border-box;
    transition: all ease-in-out 100ms;
    position: relative;
    flex: none;
    &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro", sans-serif;
        position: absolute;
        top: -1px;
        left: 2px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 2px;
        opacity: 0;
        box-sizing: border-box;
        transition: all ease-in-out 100ms;
    }
    &.checkbox-large{
        width: 25px;
        height: 25px;
        &::after{
            font-size: 19px;
            top: -2px;
        }
    }
}
//--- Checked style
.checked {
    .checkbox-style:after {
        opacity: 1;
    }
}
</style>