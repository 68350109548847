<template>
    <vue-final-modal
        v-model="modelValue"
        :display-directive="lazy ? 'if' : 'show'"
        overlay-transition="vfm-fade"
        content-transition="vfm-slide-down"
        :content-class="[
            'pop-modal modal',
            size !== 'md' ? `modal-${size}`: ''
        ]"
        :z-index-fn="zIndexFn"
    >
        <div
            v-if="$slots['modal-header'] || title || showClose"
            class="modal-header"
        >
            <slot name="modal-header">
                <div
                    v-if="title"
                    class="d-flex align-items-start"
                >
                    <h2 class="h3 m-0">
                        {{ title }} 
                    </h2>
                </div>
            </slot>
            <button
                v-if="showClose"
                type="button"
                aria-label="Close"
                class="btn-close mb-auto"
                @click="emit('update:modelValue', false)"
            >
            </button>
        </div>
        <div class="modal-body">
            <slot :close="close"></slot>
        </div>
        <div
            v-if="$slots['modal-footer']"
            class="modal-footer"
        >
            <slot
                name="modal-footer"
                :close="close"
            ></slot>
        </div>
    </vue-final-modal>
</template>
<script setup>
import { useVModel, useCssVar } from '@vueuse/core';
import { VueFinalModal } from 'vue-final-modal';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    showClose: {
        type: Boolean,
        default: true,
    },
    size: {
        type: String,
        default: 'md',
        validator: (val) => {
            return ['sm', 'md', 'lg', 'xl'].includes(val);
        },
    },
    title: {
        type: String,
        default: '',
    },
    lazy: {
        type: Boolean,
        default: true,
    },
})

const emit = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emit)

const close = () => {
    modelValue.value = false;
}

const modalBackdropZIndex = useCssVar('--zindex-modal-backdrop');

const zIndexFn = ({ index }) => {
    const baseZIndex = parseInt(modalBackdropZIndex.value);
    return baseZIndex + 2 * index;
}
</script>