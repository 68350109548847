<template>
    <component
        :is="computedTag"
        class="btn"
        :class="[
            {
                'w-100': block,
                'disabled': disabled,
                'btn-pill': pill,
            },
            variant ? `btn-${outline ? 'outline-' : ''}${variant}` : '',
            size != 'md' ? `btn-${size}` : ''
        ]"
        :disabled="disabled || null"
        :aria-disabled="computedTag === 'a' ? disabled || null : null"
        :tabindex="disabled ? -1 : null"
        :type="type"
        :href="!disabled ? href : null"
        role="button"
        @click="$emit('click', $event)"
    >
        <slot>
            <slot name="left-icon">
                <i
                    v-if="leftIcon"
                    :class="leftIcon"
                    aria-hidden="true"
                ></i>
            </slot>

            <span v-if="text">
                {{ text }}
            </span>

            <slot name="right-icon">
                <i
                    v-if="rightIcon"
                    :class="rightIcon"
                    aria-hidden="true"
                ></i>
            </slot>
        </slot>
    </component>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
    text: {
        type: String,
        default: null,
    },
    leftIcon: {
        type: String,
        default: null,
    },
    rightIcon: {
        type: String,
        default: null,
    },
    tag: {
        type: String,
        default: 'button',
    },
    href: {
        type: String,
        default: null,
    },
    variant: {
        type: String,
        default: 'secondary',
        validator: (val) => {
            return ['primary', 'secondary', 'success', 'danger', 'none'].includes(val)
        },
    },
    outline: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: null,
        validator: (val) => {
            if (!val) return true;
            return ['lg', 'sm'].includes(val)
        },
    },
    pill: {
        type: Boolean,
        default: false,
    },
    block: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'button',
        validator: (val) => {
            return ['button', 'submit', 'reset'].includes(val)
        },
    },
})
defineEmits(['click'])

const computedTag = computed(() => {
    if (props.href) {
        return 'a'
    }
    return props.tag
}) 
</script>