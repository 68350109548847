<template>
    <div class="scale-wrapper">
        <div class="scale-range-container">
            <div class="scale-range-wrapper" :style="calculatedHeight">
                <div class="bg-in-scale-range"></div>
                <div v-show="showRangeSelector" :style="computedRangeStyle" class="filled-in-scale-range"></div>
                <input 
                    class="scale-range" 
                    ref="range" 
                    v-model.number="rangeModel" 
                    type="range" 
                    min="0" 
                    :max="rangeMax" 
                    :class="hideRangeSelectorClass"
                    :style="calculatedHeight"
                    :aria-label="instruction"
                    v-on:mousedown.left.once="rangeClicked" 
                    v-on:touchstart.once="rangeClicked"
                    v-on:touchstart.prevent="toucheMove"
                    v-on:touchmove.prevent="toucheMove"
                    v-on:touchend.prevent="touchEnd"
                    @input="onInput"
                    @change="onChangeRange" />
            </div>
        </div>
        <ul class="scale-list" ref="scale">
            <scale 
                v-for="(scale, index) in scales"
                :key="`scales${scale.id}:${index}`"
                :scale="scale"
                :index="index"
                :selectedIndex="selectedIndex"
                @picked="onChangeScales"
            />
        </ul>
    </div>
</template>
<script>
import Scale from './Scale.vue';
export default {
    name: "ScaleList",
    props: {
        scales: {
            type: Array,
            deafult: () => [],
        },
        initialSelectedId:{
            type: Number,
            required: false,
            default: null,
        },
        instruction: {
            type: String,
            required: false,
            default: "",
        }
    },
    components: {
        Scale,
    },  
    emits: ['picked'],
    data(){
        return {
            rangeModel: null,
            selectedIndex: null,
            scaleHeight: 465,
        };
    },
    computed: {
        rangeMax(){
            return this.scales.length - 1;
        },
        computedRangeStyle(){
            if(this.rangeModel === 0 || this.rangeModel === this.rangeMax){
                return {right: `${(this.rangeMax - this.rangeModel) / this.rangeMax * 100}%`};
            }
            let numerator = this.rangeMax - this.rangeModel;
            let denominator = this.rangeMax
            let adjustment = (((numerator / denominator) * 100) - 50) * 24 / this.getRangeInputHeight;
            return {right: `${(numerator / denominator * 100) - adjustment}%`};
        },
        showRangeSelector(){
            return this.rangeModel !== null;
        },
        hideRangeSelectorClass(){
            return this.showRangeSelector ? '' : 'no-slider-thumb';
        },
        getRangeInputHeight(){
            return (this.scaleHeight / this.scales.length) * (this.scales.length - 1) + 24;
        },
        calculatedHeight(){
            return {"width": `${this.getRangeInputHeight}px`}
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        
        if(this.initialSelectedId !== null){
            let indexOfSelected = this.scales.findIndex(s => s.id === this.initialSelectedId);
            this.selectedIndex = indexOfSelected;
            this.rangeModel = (this.scales.length - 1 - indexOfSelected );
        }

        this.$nextTick(() => {
            this.scaleHeight = this.$refs.scale.clientHeight;
        });
    },
    methods: {
        handleResize(){
            if(this.scaleHeight !== this.$refs.scale.clientHeight){
                this.scaleHeight = this.$refs.scale.clientHeight;
            }
        },
        onChangeScales(choiceId){
            let indexOfSelecte = this.scales.findIndex(s => s.id === choiceId);
            this.rangeModel = (this.scales.length - 1 - indexOfSelecte );
            this.selectedIndex = indexOfSelecte;
            this.$emit('picked', choiceId)
        },
        onChangeRange(){
            let index = this.scales.length - 1 - this.rangeModel; 
            let choiceId = this.scales[index].id;
            this.selectedIndex = index;
            this.$emit('picked', choiceId);
        },
        onInput(){
            let index = this.scales.length - 1 - this.rangeModel;
            this.selectedIndex = index;
        },
        toucheMove(event){
            let top = event.target.getBoundingClientRect().top;
            let touchPosition = event.touches[0];
            let index = Math.floor((touchPosition.clientY - top) * (this.scales.length) / (this.getRangeInputHeight));
            index = Math.max(Math.min(index, this.scales.length - 1), 0)
            if (this.selectedIndex !== index){
                this.selectedIndex = index;
                this.rangeModel = (this.scales.length - 1 - index );
            }
            
        },
        touchEnd(){
            let choiceId = this.scales[this.selectedIndex].id;
            this.$emit('picked', choiceId);
        },
        rangeClicked(event){
            if(this.rangeModel === null){
                this.rangeModel = 0;
                this.$nextTick(() => {
                    this.$refs.range.click(event);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.scale-wrapper {
    position: relative;
    display: flex;
    padding-left: 1em;
    ul.scale-list {
        padding: 0;
        margin: 0;
        flex-grow: 1;
    }
    .scale-range-container {
        width: 0;
        display: flex;
        flex-direction: column-reverse;
        z-index: 1;
        .scale-range-wrapper {
            transform: translate(0px, 64px) rotate(-90deg);
            transform-origin: 0% 0%;
            .scale-range {
                -webkit-appearance: none;
                height: 68px;
                background-color: transparent;
                color: transparent;
                border-radius: 5px;
                border:none;
                padding: 0;
                cursor: pointer;
                z-index: 2;
                outline:none;  
            }
            .scale-range::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 26px;
                width: 26px;
                border-radius: 50%;
            }
            .scale-range.no-slider-thumb::-webkit-slider-thumb {
                display: none;
            }

            .scale-range::-moz-range-thumb {
                height: 18px;
                width: 18px;
                border-radius: 50%;
            }
            .scale-range.no-slider-thumb::-moz-range-thumb {
                display: none;
                //hacky stuff to hide thumb in firefox
                background-color: transparent;
                border-color: transparent;
            }

            .scale-range::-ms-thumb {
                height: 18px;
                width: 18px;
                border-radius: 50%;
            }
            .scale-range.no-slider-thumb::-ms-thumb {
                background-color: transparent;
                border-color: transparent;
            }
            .scale-range::-ms-track {
                /* Hides the slider so custom styles can be added */
                background: transparent; 
                border-color: transparent;
                color: transparent;
                outline:none;
            }
            .scale-range::-ms-fill-lower{
                 background: transparent; 
            }
            .bg-in-scale-range {
                height: 10px;
                position: absolute;
                border-radius: 10px;
                top: 29px;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
            .filled-in-scale-range {
                height: 10px;
                position: absolute;
                border-radius: 10px;
                top: 29px;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
}

</style>