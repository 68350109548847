<template>
    <div class="progress">
        <slot></slot>
    </div>
</template>
<script setup>
defineProps({
    value: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
    },
    animated: {
        type: Boolean,
        default: false,
    },
    striped: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String,
        default: null,
    },
    precision: {
        type: Number,
        default: 0,
    },
})

</script>