import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createGtm } from '@gtm-support/vue-gtm';
import applicationInsights from './helpers/applicationInsights.js'
import { i18n } from './services/i18n';
import VueScrollTo from 'vue-scrollto';
import 'vue-final-modal/style.css';
import { createVfm } from 'vue-final-modal';
import 'vue-select/dist/vue-select.css';

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

const isProduction = import.meta.env.VITE_ENV === 'prod';

const app = createApp(App)

const vfm = createVfm()

app.config.globalProperties.applicationInsights = applicationInsights;

if(isProduction){
    app.use(
        createGtm ({
            id: import.meta.env.VITE_GTM_TAG,
            defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
            enabled: false, // enable manually only for users who have accepted privacy policy
            debug: false, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: router, // Pass the router instance to automatically sync with router (optional)
            ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        })
    );
}

app.use(store).use(router).use(i18n).use(VueScrollTo, {
    easing: 'ease-in-out',
    offset: -55,
    duration: 1000,
})

app.use(vfm)

app.mount('#app')

// Sentry.init({
//     Vue,
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     release: import.meta.env.VITE_BUILD_VERSION,
//     environment: import.meta.env.VITE_ENV,
//     logErrors: !isProduction, //Call vue error handler if not in production
//     integrations: [new Integrations.BrowserTracing({
//         beforeNavigate: context => {
//             return {
//                 ...context,
//                 // Could use your UI's routing library to find the match
//                 name: location.pathname
//                     .replace(/\/k\/[\d\w\-_]+/g, "/k/:accesKey")
//                     .replace(/\/u\/\d+/g, "/u/:surveyPushId"),
//             };
//         },
//     })],
//     denyUrls: ["disableSentry=true"],
//     sampleRate: 1.0, //Percentage of errors sent
//     tracesSampleRate: isProduction ? 0.25 : 1.0, //Percentage of transactions sent
// });