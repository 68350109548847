<template>
        <li class="smiley-button">
            <label :for="idComputed" :style="{paddingLeft: paddingLeft + 'em'}" :class="[checkedComputed, { focusStyle : focusStyle }]">
                <span class="smiley" :class="smileyComputed"></span>
                <input type="radio" :id="idComputed" :name="groupComputed" :value="valueComputed" @click="$emit('click', model)" @focus="focusStyle = true" @blur="focusStyle = false" />
                <span class="label">{{ labelComputed }}</span>
            </label> 
        </li>
</template>

<script>
import { Functions } from '../../helpers/functions';
export default {    
    name: "SmileyButton",
    emits: ['click'],
    data: function() {
        return {
            focusStyle: false,
        };
    },
    props: {
        questionChoice: {
            type: Object,
            required: true,
            default: () => {},
        },
        selectedChoices: {
            type: Array,
            default: () => [],
        },
        isHME: {
            type: Boolean,
            required: false,
            default: false,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        paddingLeft: {
            type: Number,
            required: false,
        }
    },
    computed: {
        smileyComputed() {
            return Functions.getSmileyIconClass(this.questionChoice.numericValue, this.isHME)
        },
        idComputed() {
            return `q${this.questionChoice.questionId}qc${this.questionChoice.id}`;
        },
        labelComputed() {
            return this.questionChoice.choice;
        },
        groupComputed(){
            return `choiceGroup${this.questionChoice.questionId}`;
        },
        valueComputed() {
            return this.questionChoice.numericValue;
        },
        checkedComputed() {
            return this.selectedChoices.includes(this.questionChoice.id) ? 'checked' : '';
        },
    },
}
</script>

<style lang="scss" scoped>
// Parent li
li {
    list-style: none;
    transition: all ease-in 100ms;
    margin-bottom: 0;
}
label {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    &.checked {
        .label {
            font-weight: 400;
        }
    }
}
// Smily
.smiley {
    width: 35px;
    min-width: 35px;
    height: 35px;
}
.smiley::before {
    width: 35px;
    height: 35px;
    top: 0px;
    left: 0px;
}
input[type="radio"] {   
    visibility: visible !important;
    opacity: 0 !important;
}
// Label text
.label {
    padding: 0 10px;
    font-size: 1em;
    font-weight: 300;
}

</style>
