<template>
    <div
        class="comment-area"
        :class="noPaddingClass"
        :style="{ paddingLeft: paddingLeft + 'em' }"
    >
        <pop-button
            v-show="showButton"
            :label="$t('comment.submitIdea')"
            type="default"
            icon="fal fa-comment"
            :onClick="showCommentPressed"
            :block="false"
        ></pop-button>

        <fieldset>
            <label
                v-if="isPureTextQuestion"
                :for="inputName"
                style="display: none"
            >
                {{ label }}
            </label>
            <template v-else-if="showCommentTextArea">
                <legend>
                    {{ $t("comment.submitIdea") }}
                </legend>

                <div v-if="labelComputed.length > 1"
                     class="d-inline-flex mb-2">
                    <span
                        v-if="showLabelIcon"
                        class="smiley"
                        :class="smileyComputed"
                    />
                    <label
                        :for="inputName"
                        class="sub-label m-0"
                        v-html="labelComputed"
                    >
                    </label>
                </div>
            </template>

            <input
                v-if="isShortTextQuestion"
                class="form-control"
                :id="idComputed"
                :name="inputName"
                v-model="text"
                :placeholder="computedPlaceholder"
                :autofocus="autofocus"
                :maxlength="maxTextLength"
                v-on:input="update"
            />
            <textarea
                v-else
                v-show="showCommentTextArea"
                class="form-control popTextarea"
                style="resize: none;"
                :id="idComputed"
                :name="inputName"
                v-model="text"
                :placeholder="computedPlaceholder"
                :rows="4"
                :max-rows="maxRows"
                :autofocus="autofocus"
                :maxlength="maxTextLength"
                @input="update"
            >
            </textarea>
            <span v-show="showRemainingText">{{
                $t("comment.charactersRemaining", {
                    amount: maxTextLength - text.length,
                })
            }}</span>
        </fieldset>
    </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import PopButton from "./PopButton.vue";
import { TextInputLength, QuestionType } from "../helpers/constants";
import { Functions } from "../helpers/functions";

export default {
    name: "CommentTextArea",
    components: {
        PopButton,
    },
    props: {
        id: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        inputName: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        maxRows: {
            type: Number,
        },
        autofocus: {
            type: Boolean,
        },
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        hasBeenTriggered: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPureTextQuestion: {
            type: Boolean,
            required: false,
            default: false,
        },
        isShortTextQuestion: {
            type: Boolean,
            required: false,
            default: false,
        },
        paddingLeft: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            text: "",
            debounceFunction: undefined,
            showComment: false,
        };
    },
    computed: {
        ...mapGetters(["getCommentDebounceFunction"]),
        labelComputed() {
            return Functions.renderMarkdown(this.label, true);
        },
        showCommentTextArea() {
            return this.showComment || this.hasBeenTriggered;
        },
        idComputed() {
            return this.id ?? this.inputName;
        },
        showButton() {
            return !this.showCommentTextArea;
        },
        noPaddingClass() {
            return this.isPureTextQuestion ? "no-padding-top" : "";
        },
        maxTextLength() {
            return this.isShortTextQuestion
                ? TextInputLength.shortTextLength
                : TextInputLength.longTextLength;
        },
        showRemainingText() {
            return this.text.length / this.maxTextLength > 0.75;
        },
        computedPlaceholder() {
            if (!Functions.isStringNilOrWhitespace(this.placeholder)) {
                return this.placeholder;
            }
            return this.$t("comment.placeholderTextQuestion");
        },
        smileyComputed() {
            let isHME =
                this.question.questionType === QuestionType.hME ||
                this.question.questionType ===
                    QuestionType.hMEWithCommentForAllChoices;

            let numericValue = null;
            if (
                !Functions.isNil(this.question.savedAnswer) &&
                !Functions.isNil(this.question.savedAnswer.questionChoiceIds) &&
                this.question.savedAnswer.questionChoiceIds.length > 0
            ) {
                let choiceId = this.question.savedAnswer.questionChoiceIds[0];
                numericValue = this.question.questionChoices.find(
                    (qc) => qc.id === choiceId
                ).numericValue;
            }
            return Functions.getSmileyIconClass(numericValue, isHME);
        },
        showLabelIcon() {
            return (
                !this.question.commentInstruction &&
                (this.question.questionType === QuestionType.smileyButton ||
                    this.question.questionType ===
                        QuestionType.smileyButtonWithCommentForAllChoices ||
                    this.question.questionType === QuestionType.hME ||
                    this.question.questionType ===
                        QuestionType.hMEWithCommentForAllChoices)
                && this.labelComputed?.length > 0
            );
        },
    },
    mounted() {
        if (
            this.question.savedAnswer !== null &&
            this.question.savedAnswer.comment !== null &&
            this.question.savedAnswer.comment.length > 0
        ) {
            this.text = this.question.savedAnswer.comment;
            this.showComment = true;
        }
        let debounceFunction = this.getCommentDebounceFunction(
            this.question.id
        );
        if (debounceFunction === undefined) {
            this.createDebouncedPostSpecificComment({
                questionId: this.question.id,
                func: this.getUpdateFunction(),
            });
            debounceFunction = this.getCommentDebounceFunction(
                this.question.id
            );
        }
        this.debounceFunction = debounceFunction;
    },
    methods: {
        ...mapActions([
            "createDebouncedPostSpecificComment",
            "postSpecificQuesion",
        ]),
        update() {
            this.debounceFunction();
        },
        getUpdateFunction() {
            return () => {
                let savedAnswer = { ...this.question.savedAnswer };
                if (this.isPureTextQuestion && this.text.length > 0) {
                    savedAnswer = {
                        questionChoiceIds: [
                            this.question.questionChoices[0].id,
                        ],
                        questionId: this.question.id,
                    };
                } else if (this.isPureTextQuestion && this.text.length === 0) {
                    savedAnswer = {
                        questionChoiceIds: [],
                        questionId: this.question.id,
                    };
                }
                this.postSpecificQuesion({
                    questionId: this.question.id,
                    ...savedAnswer,
                    comment: this.text.trim(),
                });
            };
        },
        showCommentPressed() {
            this.showComment = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.smiley {
    width: 1.3em;
    height: 1.3em;
    margin-left: 1.5em;
}
.smiley::before {
    width: 1.3em;
    height: 1.3em;
    top: 0;
    left: -1.5em;
}
.comment-area {
    padding: 2em 2em 0 2em;
    textarea.popTextarea {
        overflow-y: auto !important;
        &:focus::placeholder {
            color: transparent;
        }
    }
}
.comment-area.no-padding-top {
    padding-top: 0;
}
.popTextarea {
    border-radius: 3px;
}
label {
    font-weight: 500;

    &.sub-label {
        font-weight: inherit;
    }
}
</style>
