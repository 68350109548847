<template>
        <li class="scale" :class="styleComputed" @click="$emit('picked', scale.id)">
            <span class="content"><span class="notranslate">{{ this.scale.numericValue }}</span> {{ textComputed }}</span>
        </li>
</template>

<script>
export default {
    name: 'Scale',
    emits: ['picked'],
    props: {
        scale: {
            type: Object,
            required: true,
        },
        index: {
            type:Number,
            required: true,
        },
        selectedIndex:{
            type:Number,
            requried: false,
            default: null,
        },
    },
    computed:{
        textComputed() {
            return `${this.scale.numericValue}` === this.scale.choice ? '' : this.scale.choice;
        },
        styleComputed(){
            if(this.index === this.selectedIndex){
                return 'SCALE_PICKED';
            }
            else if(this.index > this.selectedIndex && this.selectedIndex != null){
                return 'SCALE_ACTIVE';
            }
            return '';
        },
    },
};
</script>
<style lang="scss" scoped>
.scale {
    list-style: none;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
    margin-left: -1em;
    padding-left: 1em;
    &:before {
        content: "";
        width: 35px;
        height: 2px;
        box-sizing: border-box;
        position: absolute;
        left: 35px;
        bottom: 22px;
        display: block;
        margin-left: 1em;
    }
    .content {
        margin-left: 85px;
        font-size: 1em;
    }
}
.SCALE_PICKED {
    .content {
        font-weight: 500;
    }

}
</style>